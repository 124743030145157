import "./range-condition-editor.scss";

import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteEditorButton } from "../../../../../../components/delete-editor-button/delete-editor-button";
import type { IRange } from "../../../../../../components/editors/range";
import { RangeValueEditor } from "../../../../../../components/editors/range";
import type { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import type { RangeCondition } from "../../../../../../components/query-builder/models/range-condition";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../../store/groups";
import { useVariable, useVariablesActions } from "../../../../store/variables";
import { useProjectRunActions } from "../../../../store/projects";
import { useState } from "react";

interface Props {
  condition: RangeCondition;
  groupId?: number;
  variableId?: number;
  onDelete?: () => void;
}

export function RangeConditionEditor(props: Props): JSX.Element {
  const { condition, groupId, variableId, onDelete } = props;

  useGroupsNudge();
  const { nudgeState, removeGroupDefinition, hasGroupChanged } = useGroupsActions();
  const { haveVariablesSelectionChanged } = useVariablesActions();
  const { hasRun } = useProjectRunActions();
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  let definition: QueryBuilderModel | undefined;

  if (groupId !== undefined) {
    const group = useGroup(groupId);
    definition = group.definition;
  } else if (variableId !== undefined) {
    definition = useVariable(variableId).variableDefinition;
  }
  const handleAgeRangeSelected = (range: IRange): void => {
    if (groupId !== undefined) {
      hasGroupChanged(true);
    } else if (variableId !== undefined) {
      haveVariablesSelectionChanged(true);
    }
    hasRun(false);
    condition.setRangeLowerBound(range.lowerBound);
    condition.setRangeUpperBound(range.upperBound);
    nudgeState();
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  const handleDelete = (): void => {
    if (onDelete) {
      onDelete();
      return;
    }

    if (definition == null) {
      console.error("Definition is undefined or null");
    } else {
      removeGroupDefinition(condition, definition);
    }
  };

  return (
    <div
      className={`${isOnRemovalMode ? "editor-removal-mode editor-highlighted-condition" : "editor-highlighted-condition"}`}
    >
      <DeleteEditorButton
        onDelete={handleDelete}
        shouldHide={true}
        onMouseEnter={() => setIsOnRemovalMode && setIsOnRemovalMode(true)}
        onMouseLeave={() => setIsOnRemovalMode && setIsOnRemovalMode(false)}
      />
      <RangeValueEditor
        valueName="Age"
        lowerRange={condition.getRangeLowerBound()}
        upperRange={condition.getRangeUpperBound()}
        onRangeChosen={handleAgeRangeSelected}
        maxAllowed={condition.getMaximumAllowed()}
        minAllowed={condition.getMinimumAllowed()}
        error={error}
      />
    </div>
  );
}
