import { Button, Grid, Tooltip } from "@mui/material";
import { WppIconChevron, WppToggle } from "@platform-ui-kit/components-library-react";
import { useState } from "react";
import type { Group } from "../store/groups";
import { useGroupsActions, useGroupsData } from "../store/groups";
import { GroupCard } from "./components/group-card";

const getTooltipMessage = (count: number, itemType: string): string => {
  return count >= 10 ? `You can add up to 10 ${itemType}` : "";
};

export function GroupsSection(): JSX.Element {
  const { addGroup } = useGroupsActions();

  const groups = useGroupsData();
  const groupsCount = groups.length;

  const [groupsIsOpen, setGroupsIsOpen] = useState(true);
  const [showStringDefinitions, setShowStringDefinitions] = useState(false);

  const handleGroupsToggle = (): void => {
    setGroupsIsOpen(!groupsIsOpen);
  };

  const addAudienceTooltipMessage = getTooltipMessage(groupsCount, "groups");

  const groupTooltip = (
    <Tooltip title={addAudienceTooltipMessage}>
      <Button
        aria-controls={groupsIsOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={groupsIsOpen ? "true" : undefined}
        onClick={() => {
          if (groupsCount < 10) {
            addGroup();
            setTimeout(() => {
              document.querySelector("#groups-section-container")?.scrollTo(0, 0);
            }, 0);
          }
        }}
        disableRipple
      >
        <div className="section-add-button">+ Group</div>
      </Button>
    </Tooltip>
  );

  const groupsSection = (
    <Grid item container className="project-config-section">
      <Grid item xs={6} className="section-button">
        <Button
          aria-controls={groupsIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={groupsIsOpen ? "true" : undefined}
          onClick={handleGroupsToggle}
          disableRipple
        >
          <WppIconChevron className={groupsIsOpen ? "chevron-open" : "chevron-closed"} />
          <div className="section-count">
            <strong>Groups (columns)</strong> <span className="count">{groups.length}</span>
          </div>
        </Button>
      </Grid>
      <Grid item xs={6} className="section-add">
        {groupTooltip}
      </Grid>
    </Grid>
  );

  const groupsCards = (
    <div id="groups-section-container" className={groupsIsOpen ? "section-open columns" : "section-closed columns"}>
      <div className="groups-string-definition">
        <div className="toggle">
          <WppToggle
            labelConfig={{
              text: "Show string definitions",
            }}
            checked={showStringDefinitions}
            onWppChange={({ detail: { checked } }) => {
              setShowStringDefinitions(checked);
            }}
            required
          />
        </div>
      </div>
      {groups.map((group: Group) => (
        <GroupCard key={group.id} groupId={group.id} stringDefinition={showStringDefinitions} />
      ))}
    </div>
  );

  return (
    <>
      {groupsSection}
      {groupsCards}
    </>
  );
}
