import "./group-condition-editor.scss";

import type { SelectChangeEvent } from "@mui/material";
import { Box, Grid, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { DeleteEditorButton } from "../../../../../../components/delete-editor-button/delete-editor-button";
import { conditionDragged } from "../../../../../../components/query-builder/events-handlers/condition-dragged";
import { convertGroupToSingleCondition } from "../../../../../../components/query-builder/events-handlers/group-converter";
import { GroupCondition } from "../../../../../../components/query-builder/models/group-condition";
import { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import { DragType } from "../../../../../../models/query-builder/drag-type";
import { Operator } from "../../../../../../models/query-builder/operator";
import type { QueryCondition } from "../../../../../../models/query-builder/query-condition";
import { OperatorSelect } from "../../../../../create-audience/components/query-builder/editors/operator-select";
import type { Group } from "../../../../store/groups";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../../store/groups";
import { GroupsQueryBuilder } from "../../groups-query-builder";
import { DragTarget } from "./components/drag-target";
import { useState } from "react";

interface DropZoneProps {
  condition: QueryCondition;
  dragType: DragType;
  onConditionDraggedOver: (type: DragType) => void;
  definition: QueryBuilderModel;
}

const chainedOperators = new Set([Operator.And, Operator.Or]);

function DropHereZone(props: DropZoneProps): JSX.Element | null {
  const { condition, dragType, onConditionDraggedOver, definition } = props;

  const isRootCondition = condition.getParent() === null;
  const isDragTargetSelf = definition.rootCondition.getConditionBeingDragged() === condition;

  const canRender = !(isRootCondition || isDragTargetSelf);

  if (canRender) {
    return (
      <DragTarget
        onDragEnter={() => {
          onConditionDraggedOver(dragType);
        }}
        onDragLeave={() => {}}
        dragType={dragType}
      />
    );
  }
  return null;
}

interface IGroupConditionEditorProps {
  condition: GroupCondition;
  groupId: Group["id"];
}

export function GroupConditionEditor(props: IGroupConditionEditorProps): JSX.Element | null {
  const { condition, groupId } = props;

  useGroupsNudge();
  const { updateGroupDefinition, nudgeState, removeGroupDefinition, setGroupsDropTarget } = useGroupsActions();
  const [isOperatorOnRemovalMode, setIsOperatorOnRemovalMode] = useState(false);
  const [isGroupOnRemovalMode, setIsGroupOnRemovalMode] = useState(false);

  const definition = useGroup(groupId).definition;

  const isDragTarget = condition.getIsDragTarget();

  const isParentGroupWithSameOperator = (): boolean => {
    const thisOperator = condition.getOperator();

    if (!chainedOperators.has(thisOperator)) {
      return false;
    }

    const parent = condition.getParent();

    if (parent instanceof GroupCondition) {
      return parent.getOperator() === thisOperator;
    }

    return false;
  };

  const isRootCondition = condition.getParent() === null;

  const isChained = isParentGroupWithSameOperator();

  const handleOperatorChanged = (event: SelectChangeEvent): void => {
    const typedOperator = event.target.value as Operator;
    condition.setOperator(typedOperator);
    updateGroupDefinition(groupId, QueryBuilderModel.fromExisting(definition));
    nudgeState();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    condition.setName(event.target.value);
    nudgeState();
  };

  const leftOperand = condition.getLeftOperand();
  const rightOperand = condition.getRightOperand();

  const handleDragStart = (): void => {
    const newModel = QueryBuilderModel.fromExisting(definition);
    newModel.setConditionBeingDragged(condition);
    newModel.setDraggedOverCondition(condition.getParent()!, DragType.OnTop);
    updateGroupDefinition(groupId, newModel);
  };

  const handleDragEnter = (type: DragType): void => {
    setGroupsDropTarget(groupId);
    const newModel = QueryBuilderModel.fromExisting(definition);
    newModel.setDraggedOverCondition(condition, type);
    updateGroupDefinition(groupId, newModel);
  };

  const handleDragLeave = (): void => {
    const newDefinition = conditionDragged(condition, definition);
    updateGroupDefinition(groupId, newDefinition);
    if (props.condition.NAME === "EmptyCondition") {
      newDefinition.cancelDrag();
    }
  };

  const handleDelete = (): void => {
    removeGroupDefinition(condition, definition);
  };

  const handleConvertGroupToSingleCondition = (): void => {
    console.log("convert group to single condition");
    const newDefinition = convertGroupToSingleCondition(condition, definition);
    updateGroupDefinition(groupId, newDefinition);
  };

  const deleteButton = (
    <DeleteEditorButton
      onDelete={handleDelete}
      shouldHide={true}
      onMouseEnter={() => setIsGroupOnRemovalMode(true)}
      onMouseLeave={() => setIsGroupOnRemovalMode(false)}
    />
  );

  const dropHereAbove = isDragTarget ? (
    <DropHereZone
      definition={definition}
      condition={condition}
      dragType={DragType.Above}
      onConditionDraggedOver={handleDragEnter}
    />
  ) : null;

  const dropHereBelow = isDragTarget ? (
    <DropHereZone
      definition={definition}
      condition={condition}
      dragType={DragType.Below}
      onConditionDraggedOver={handleDragEnter}
    />
  ) : null;

  const nameField = (
    <TextField
      className="group-name-input"
      variant="standard"
      value={condition.getName()}
      onChange={handleNameChange}
      sx={{ visibility: isRootCondition ? "hidden" : "", display: isChained ? "none" : null }}
    />
  );

  const editorLeft =
    leftOperand === null ? (
      <DragTarget
        onDragEnter={() => {
          handleDragEnter(DragType.LeftOperand);
        }}
        onDragLeave={() => {
          handleDragLeave();
        }}
        dragType={DragType.LeftOperand}
      />
    ) : (
      <GroupsQueryBuilder groupId={groupId} condition={leftOperand} />
    );

  const editorRight =
    rightOperand === null ? (
      <DragTarget
        onDragEnter={() => {
          handleDragEnter(DragType.RightOperand);
        }}
        onDragLeave={() => {
          handleDragLeave();
        }}
        dragType={DragType.RightOperand}
      />
    ) : (
      <GroupsQueryBuilder groupId={groupId} condition={rightOperand} />
    );

  const operator = <OperatorSelect handleOperatorChanged={handleOperatorChanged} condition={condition} />;

  const incompleteConditionOperatorButton = (
    <Tooltip title="Remove logic operator">
      <IconButton size="small">
        <Grid item xs={3}>
          <DeleteEditorButton
            onDelete={() => {
              condition.isConditionEmpty() ? handleDelete() : handleConvertGroupToSingleCondition();
            }}
            shouldHide={false}
            onMouseEnter={() => setIsOperatorOnRemovalMode(true)}
            onMouseLeave={() => setIsOperatorOnRemovalMode(false)}
          />
        </Grid>
      </IconButton>
    </Tooltip>
  );

  return (
    <div
      className={`${isGroupOnRemovalMode ? "editor-removal-mode editor-group-condition editor-group-condition-highlighted" : "editor-group-condition editor-group-condition-highlighted"}`}
      draggable
      onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragStart();
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragEnter(DragType.OnTop);
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      }}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleDragLeave();
      }}
    >
      <Stack>
        {!isChained && <Box className={"editor-group-condition-delete-button"}>{deleteButton}</Box>}
        <Box>{leftOperand && dropHereAbove}</Box>
        <Box>{nameField}</Box>
        <Box>{editorLeft}</Box>
        <Box
          className={`${isOperatorOnRemovalMode ? "editor-removal-mode operator" : "operator"}`}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {operator}
          {condition.isConditionIncomplete() && incompleteConditionOperatorButton}
        </Box>
        <Box>{editorRight}</Box>
        <Box>{rightOperand && dropHereBelow}</Box>
      </Stack>
    </div>
  );
}
