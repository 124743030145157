import { Button, Grid, Tooltip } from "@mui/material";
import { WppIconChevron } from "@platform-ui-kit/components-library-react";
import { useState, useEffect } from "react";
import type { VariableData } from "../../../store/variables";
import { useVariablesData, useIsVariableSectionInInitialState } from "../../../store/variables";
import { VariableCard } from "./components/variable-card";
import { VariablesWrapperHeader } from "./components/variables-wapper-header";

const MAX_VARIABLES_COUNT_MESSAGE = "You can add up to 10 variables";

interface IVariablesSectionProps {
  isDragging: boolean;
}

export function VariablesSection({ isDragging }: IVariablesSectionProps): JSX.Element {
  const variables = useVariablesData();
  const isVariablesInInitialState = useIsVariableSectionInInitialState();

  const [variablesIsOpen, setVariablesIsOpen] = useState(true);
  const [variablesCount, setVariablesCount] = useState(isVariablesInInitialState ? 0 : variables.length);

  const getTooltipMessage = (): string => {
    return variablesCount >= 10 ? MAX_VARIABLES_COUNT_MESSAGE : "";
  };

  const handleVariablesToggle = (): void => {
    setVariablesIsOpen(!variablesIsOpen);
  };

  const variablesCards =
    variables.length > 0 ? (
      <div className={`rows variables-wrapper ${variablesIsOpen ? "section-open" : "section-closed"}`}>
        <VariablesWrapperHeader />
        {variables.map((variable: VariableData) => (
          <VariableCard key={variable.id} variableId={variable.id} />
        ))}
      </div>
    ) : (
      <></>
    );

  useEffect(() => {
    if (!isDragging) {
      setVariablesCount(isVariablesInInitialState ? 0 : variables.length);
    }
  }, [variables]);

  const variablesSection = (
    <Grid item container className="project-config-section">
      <Grid item xs={6} className="section-button">
        <Button
          aria-controls={variablesIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={variablesIsOpen ? "true" : undefined}
          onClick={handleVariablesToggle}
          disableRipple
        >
          <WppIconChevron className={variablesIsOpen ? "chevron-open" : "chevron-closed"} />
          <Tooltip title={getTooltipMessage()} open={isDragging && !!getTooltipMessage()}>
            <div className="section-count">
              <strong>Variables (rows)</strong> <span className="count">{variablesCount}</span>
            </div>
          </Tooltip>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      {variablesSection}
      {variablesCards}
    </>
  );
}
