"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configGroups = configGroups;
const okta_group_1 = require("@vmlyr/common/dist/types/okta-group");
const fordConfig = (isUnderTest) => ({
    id: 1,
    oktaGroupName: isUnderTest ? okta_group_1.OKTA_DEV_FORD : okta_group_1.OKTA_HUB_FORD,
    dimensionsToIgnore: [],
    faqFilename: "faqContentFordDemo",
    logo: "Ford",
    useNewAssets: true,
    showKnowledgeHubLink: true,
    isDemoConfig: false,
    showCustomAudiences: true,
    showExplore: true,
    taxonomies: [
        { taxonomy: "Region", values: [{ name: "North America", code: "NA" }] },
        { taxonomy: "Market", values: [{ name: "United States", code: "US" }] },
        { taxonomy: "Geo", values: [{ name: "National" }, { name: "State" }, { name: "FDAF" }, { name: "DMA" }] },
        {
            taxonomy: "Client",
            values: [
                { name: "FCSD", code: "FCD" },
                { name: "Ford Apps", code: "APP" },
                { name: "Ford Direct", code: "FT2" },
                { name: "Ford Pro", code: "PRO" },
                { name: "Ford Retail", code: "FOR" },
                { name: "Lincoln Retail", code: "LIR" },
            ],
        },
        {
            taxonomy: "Brand",
            values: [
                { name: "FDAF Baltimore Washington", code: "Y3W" },
                { name: "FDAF Carolina", code: "Y3L" },
                { name: "FDAF Cincinnati North", code: "Y3I" },
                { name: "FDAF Cincinnati South", code: "Y3B" },
                { name: "FDAF Desert Ford Dealers", code: "Y3Z" },
                { name: "FDAF Greater Michigan", code: "Z3G" },
                { name: "FDAF Greater Texas", code: "Y3G" },
                { name: "FDAF Hawaii", code: "Z3H" },
                { name: "FDAF Houston", code: "Y3H" },
                { name: "FDAF Illinois", code: "Y3C" },
                { name: "FDAF Kansas City", code: "Y3K" },
                { name: "FDAF Memphis District", code: "Y3M" },
                { name: "FDAF Mountain States", code: "Y3D" },
                { name: "FDAF New England", code: "Y3N" },
                { name: "FDAF New Mexico", code: "NMX" },
                { name: "FDAF North Florida (Southern Ford)", code: "Z3B" },
                { name: "FDAF North Texas", code: "Y3Y" },
                { name: "FDAF Northeast Ohio", code: "Y3E" },
                { name: "FDAF Northern California", code: "Y3R" },
                { name: "FDAF Northwest", code: "Y3Q" },
                { name: "FDAF NW Ohio/Indiana", code: "Z3I" },
                { name: "FDAF Oklahoma", code: "Y3O" },
                { name: "FDAF Philadelphia", code: "Y3F" },
                { name: "FDAF Pittsburgh District", code: "Z3P" },
                { name: "FDAF San Diego", code: "Z3D" },
                { name: "FDAF SE Michigan", code: "Y3P" },
                { name: "FDAF South Florida", code: "Z3F" },
                { name: "FDAF South Texas", code: "Z3S" },
                { name: "FDAF Southeast", code: "Y3A" },
                { name: "FDAF Southern California", code: "Z3C" },
                { name: "FDAF Southern Quality", code: "Z3A" },
                { name: "FDAF Southern Virginia", code: "Y3V" },
                { name: "FDAF St. Louis District", code: "Y3U" },
                { name: "FDAF Tri-State New York", code: "Y3X" },
                { name: "FDAF Twin Cities", code: "Y3T" },
                { name: "FDAF Upstate (Albany)", code: "Z3U" },
                { name: "FDAF Western New York", code: "Z3E" },
                { name: "FDAF Wisconsin", code: "Y3J" },
                { name: "Ford Apps", code: "FAP" },
                { name: "Ford Blue", code: "FBL" },
                { name: "Ford Model-E", code: "FME" },
                { name: "Ford Pro", code: "PRO" },
                { name: "Lincoln", code: "LIN" },
                { name: "Lincoln Apps", code: "LAP" },
            ],
        },
        {
            taxonomy: "Product",
            values: [
                { name: "Aviator", code: "AVI" },
                { name: "Bronco", code: "BRO" },
                { name: "Bronco Sport", code: "BRS" },
                { name: "Corsair", code: "COR" },
                { name: "E-Transit", code: "ETR" },
                { name: "EcoSport", code: "ECO" },
                { name: "Edge", code: "EDG" },
                { name: "Escape", code: "ESC" },
                { name: "Expedition", code: "EXP" },
                { name: "Explorer", code: "EXL" },
                { name: "F-150", code: "F15" },
                { name: "F-150 Lightning", code: "LIT" },
                { name: "F-250 Super Duty", code: "F25" },
                { name: "Ford Accessories", code: "ACC" },
                { name: "Ford Brand", code: "FBR" },
                { name: "Ford Pass", code: "FPA" },
                { name: "Ford Pass Pro", code: "FPP" },
                { name: "Ford Pro Brand", code: "PRB" },
                { name: "Ford Service", code: "SER" },
                { name: "Lincoln Brand", code: "LBR" },
                { name: "Lincoln Way", code: "LWY" },
                { name: "Maverick", code: "MAV" },
                { name: "Mustang", code: "MUS" },
                { name: "Mustang Mach-E", code: "MAE" },
                { name: "Nautilus", code: "NAU" },
                { name: "Navigator", code: "NAV" },
                { name: "Ranger", code: "RAN" },
                { name: "Transit", code: "TRA" },
                { name: "Transit Connect", code: "TRC" },
                { name: "Vehicle Range", code: "VER" },
            ],
        },
    ],
});
const demoConfig = (isUnderTest) => ({
    id: 2,
    oktaGroupName: isUnderTest ? okta_group_1.OKTA_DEV_DEMO : okta_group_1.OKTA_HUB_DEMO,
    dimensionsToIgnore: ["FDAF"],
    faqFilename: "faqContentFordDemo",
    logo: null,
    useNewAssets: true,
    showKnowledgeHubLink: true,
    isDemoConfig: true,
    showCustomAudiences: false,
    showExplore: true,
    taxonomies: [
        { taxonomy: "Region", values: [{ name: "North America", code: "NA" }] },
        { taxonomy: "Market", values: [{ name: "United States", code: "US" }] },
        { taxonomy: "Geo", values: [{ name: "National" }, { name: "State" }, { name: "DMA" }] },
        {
            taxonomy: "Client",
            values: [
                { name: "Demo Client One", code: "DCO" },
                { name: "Demo Client Two", code: "DCTWO" },
                { name: "Demo Client Three", code: "DCTHREE" },
            ],
        },
        {
            taxonomy: "Brand",
            values: [
                { name: "Demo Brand One", code: "DBO" },
                { name: "Demo Brand Two", code: "DBTWO" },
                { name: "Demo Brand Three", code: "DBTHREE" },
            ],
        },
        {
            taxonomy: "Product",
            values: [
                { name: "Demo Product One", code: "DPONE" },
                { name: "Demo Product Two", code: "DPTWO" },
                { name: "Demo Product Three", code: "DPTHREE" },
            ],
        },
    ],
});
const fordTestConfig = (isUnderTest) => ({
    ...fordConfig(isUnderTest),
    oktaGroupName: isUnderTest ? okta_group_1.OKTA_DEV_FORD_TEST : okta_group_1.OKTA_HUB_FORD_TEST,
});
const demoTestConfig = (isUnderTest) => ({
    ...demoConfig(isUnderTest),
    oktaGroupName: isUnderTest ? okta_group_1.OKTA_DEV_DEMO_TEST : okta_group_1.OKTA_HUB_DEMO_TEST,
});
function configGroups(isUnderTest) {
    return [fordConfig(isUnderTest), demoConfig(isUnderTest), fordTestConfig(isUnderTest), demoTestConfig(isUnderTest)];
}
