import "./results-section.scss";

import { Button, CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { WppIconChevron, WppToggle } from "@platform-ui-kit/components-library-react";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "../../../assets/icon-more.svg";
import { ControlHeader } from "../../../components/control-header";
import { useProjectActions, useProjectResultsLoading, useProjectShowFigureHighlights } from "../store/projects";
import { ResultsCard } from "./components/results-card";
import { ResultsMenu } from "./components/results-menu";
import { SaveHighlightModal } from "./components/save-highlight-modal";

interface IResultsSectionProps {
  selectedColumns: string[];
  setSelectedColumns: Dispatch<SetStateAction<string[]>>;
  hasRunProject: boolean;
}

export function ResultsSection({
  selectedColumns,
  setSelectedColumns,
  hasRunProject,
}: IResultsSectionProps): JSX.Element | null {
  const [resultsIsOpen, setResultsIsOpen] = useState(true);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const showFigureHighlights = useProjectShowFigureHighlights();
  const setShowFigureHighlights = useProjectActions().setShowFigureHighlights;

  useEffect(() => {
    return () => {
      if (showFigureHighlights) {
        setShowFigureHighlights(false);
      }
    };
  }, []);
  const resultsLoading = useProjectResultsLoading();
  const { openShowSaveHighlightModal } = useProjectActions();

  const handleResultsToggle = (): void => {
    setResultsIsOpen(!resultsIsOpen);
  };

  const [highlightOptionsAreOpen, setHighlightOptionsAreOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(highlightOptionsAreOpen);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setHighlightOptionsAreOpen(event.currentTarget);
  };

  const handleMenuItemClick = (): void => {
    openShowSaveHighlightModal();
    setHighlightOptionsAreOpen(null);
  };

  const handleClose = (): void => {
    setHighlightOptionsAreOpen(null);
  };

  const saveButton = (
    <MenuItem className="project-group-option" onClick={handleMenuItemClick}>
      Edit highlights
    </MenuItem>
  );

  const menuButton = (
    <Button
      size="small"
      variant="text"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      disableRipple
    >
      <MoreIcon />
    </Button>
  );

  const menu = (
    <Menu
      className="project-highlight-options"
      anchorEl={highlightOptionsAreOpen}
      open={open}
      onClose={handleClose}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {saveButton}
    </Menu>
  );

  const resultsPane = hasRunProject ? (
    <>
      <Grid item container className="project-config-section">
        <Grid item xs={12} className="section-button">
          <Button
            aria-controls={resultsIsOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={resultsIsOpen ? "true" : undefined}
            onClick={handleResultsToggle}
            disableRipple
          >
            <WppIconChevron className={resultsIsOpen ? "chevron-open" : "chevron-closed"} />
            <div className="section-count">
              <strong>Results</strong>
            </div>
          </Button>
        </Grid>
      </Grid>
      <div className={resultsIsOpen ? "section-open rows" : "section-closed rows"}>
        <div className="explorer-container">
          <ControlHeader
            leftControl={<div className="title">Results</div>}
            rightControl={
              <div>
                <Grid item className="more-options" xs={4}>
                  {menuButton}
                </Grid>
                {menu}
              </div>
            }
          />
          {resultsLoading && (
            <div className={"flexCenter"}>
              <CircularProgress />
            </div>
          )}

          <Grid container spacing={2} alignItems="center" marginBottom={4}>
            <Grid item>
              <ResultsMenu
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                columns={columns}
                resultsLoading={resultsLoading}
              />
            </Grid>

            <Grid item marginBottom={1}>
              <WppToggle
                labelConfig={{
                  text: "Figure Highlights",
                }}
                checked={showFigureHighlights}
                onWppChange={({ detail: { checked } }) => {
                  setShowFigureHighlights(checked);
                }}
                required
              />
            </Grid>
          </Grid>

          <div className={resultsLoading ? "table-refreshing" : ""}>
            <ResultsCard selectedColumns={selectedColumns} columns={columns} setColumns={setColumns} />
          </div>
        </div>
      </div>
      <SaveHighlightModal />
    </>
  ) : null;

  return resultsPane;
}
