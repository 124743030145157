import Close from "@mui/icons-material/Close";
import { Button, Container, Grid, IconButton, InputLabel } from "@mui/material";
import {
  WppButton,
  WppCheckbox,
  WppInlineMessage,
  WppListItem,
  WppSelect,
  WppTextInput,
} from "@platform-ui-kit/components-library-react";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { userDetails } from "@vmlyr/connekdfordshared/dist/config/userDetails";
import type React from "react";
import { useRef, useState } from "react";
import ConfigurationHelper from "../../../../../../helpers/configuration-helper";
import "./save-users-modal.scss";

interface Imodal {
  user: any;
  modalState: any;
  reloadUserTable: (action?: string, editedUser?: any) => void;
  setSelectedUsers?: (userdata: any) => void;
  variant: string;
}

const activeStatusString = "Active";

const SaveUsersModal: React.FC<Imodal> = ({ user, modalState, reloadUserTable, variant }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [firstName, setFirstName] = useState(user.first_name);
  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const [lastName, setlastName] = useState(user.last_name);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState(user.email);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [job, setJob] = useState(user.job_title);
  const jobRef = useRef<HTMLInputElement | null>(null);
  const [company, setCompany] = useState(user.company);
  const companyRef = useRef<HTMLInputElement | null>(null);
  const [clientAccount, setClientAccount] = useState(user.client_account);
  const clientAccountRef = useRef<HTMLInputElement | null>(null);
  const [role, setRole] = useState<any>({ name: user.role, id: user.role_num });
  const [entity, setEntity] = useState<any>({ name: user.entity, id: user.entity_num });
  const [trained, setTrained] = useState<boolean>(user.training_complete);
  const [welcomed, setWelcomed] = useState<boolean>(user.welcome_email_sent);

  interface IUserData {
    first_name: string;
    last_name: string;
    email: string;
    job_title: string;
    company: string;
    client_account: string;
    role: number;
    entity: number;
    training_complete: boolean;
    welcome_email_sent: boolean;
  }

  function submitUser(userData: IUserData) {
    postWithToken(ConfigurationHelper.GetUpdateUserEndpoint(), {
      action: "saveUser",
      userData,
      userId: user.id,
    })
      .then(async (response) => {
        if (response.ok) {
          reloadUserTable(variant, {
            id: user.id,
            training_complete: trained,
            welcome_email_sent: welcomed,
            status_num: userDetails.userStatus.Active,
            status: activeStatusString,
          });
          modalState(false);
        } else {
          setErrorMessage(`User with the email: ${email} already exists`);
          console.error(`Error setting welcome email sent setting for ${userData.email}`);
          console.error(response);
        }
      })
      .catch((err) => {
        console.error(`There was an unexpected error: ${err}`);
      });
  }

  const refCheck = (ref: any) => {
    const inputValue = ref?.current?.querySelector(".wppTextInput").value;
    const inputId = ref?.current?.querySelector(".wppTextInput").id;
    const emailRegex = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
    if (inputValue && inputId !== "email") {
      return inputValue;
    } else if (inputId === "email" && emailRegex.test(inputValue)) {
      return inputValue;
    }
    setErrorMessage(`Please ensure that ${inputId} is filled in correctly.`);
  };

  const handleCancel = (): void => {
    modalState(false);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMessage("");
    const firstNameCheck = refCheck(firstNameRef);
    const lastNameCheck = refCheck(lastNameRef);
    const jobCheck = refCheck(jobRef);
    const emailCheck = refCheck(emailRef);
    const companyCheck = refCheck(companyRef);
    const clientAccountCheck = refCheck(clientAccountRef);

    if (firstNameCheck && lastNameCheck && jobCheck && companyCheck && clientAccountCheck) {
      submitUser({
        first_name: firstNameCheck,
        last_name: lastNameCheck,
        job_title: jobCheck,
        email: emailCheck,
        company: companyCheck,
        client_account: clientAccountCheck,
        role: role.id || userDetails.userRole.User,
        entity: entity.id || userDetails.userEntity.ID,
        training_complete: trained || false,
        welcome_email_sent: welcomed || false,
      });
    }
  };

  return (
    <Container className="save-user-modal-container" maxWidth="sm">
      <div className="save-user-modal-container-inner">
        <Grid container>
          <Grid item xs={10}>
            <h2 className="user-modal-header">Add User</h2>
          </Grid>
          <Grid item xs={2} className="close-btn">
            <IconButton className="close-btn-inner" onClick={handleCancel} size="small">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid ref={firstNameRef} item xs={12}>
            <InputLabel className="label" shrink>
              First name
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setFirstName(refCheck(firstNameRef));
              }}
              value={firstName}
              class="wppTextInput"
              id="first-name"
            />
          </Grid>
          <Grid ref={lastNameRef} item xs={12}>
            <InputLabel className="label" shrink>
              Last name
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setlastName(refCheck(lastNameRef));
              }}
              value={lastName}
              class="wppTextInput"
              id="last-name"
            />
          </Grid>
          <Grid ref={emailRef} item xs={12}>
            <InputLabel className="label" shrink>
              Email
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setEmail(refCheck(emailRef));
              }}
              value={email}
              class="wppTextInput"
              id="email"
            />
          </Grid>
          <Grid ref={jobRef} item xs={12}>
            <InputLabel className="label" shrink>
              Job title
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setJob(refCheck(jobRef));
              }}
              value={job}
              class="wppTextInput"
              id="job-title"
            />
          </Grid>
          <Grid ref={companyRef} item xs={12}>
            <InputLabel className="label" shrink>
              Company
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setCompany(refCheck(companyRef));
              }}
              value={company}
              class="wppTextInput"
              id="company"
            />
          </Grid>
          <Grid ref={clientAccountRef} item xs={12}>
            <InputLabel className="label" shrink>
              Client Account
            </InputLabel>
            <WppTextInput
              onBlur={() => {
                setClientAccount(refCheck(clientAccountRef));
              }}
              value={clientAccount}
              class="wppTextInput"
              id="client-account"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="label" shrink>
              Role
            </InputLabel>
            <WppSelect placeholder={role.name}>
              {userDetails.userRole &&
                Object.keys(userDetails.userRole).map((role, i) => (
                  <WppListItem
                    key={i}
                    onClick={() => {
                      setRole({
                        name: role,
                        id: userDetails.userRole[role as keyof typeof userDetails.userRole],
                      });
                    }}
                  >
                    <p slot="label">{role}</p>
                  </WppListItem>
                ))}
            </WppSelect>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="label" shrink>
              Entity
            </InputLabel>
            <WppSelect placeholder={entity.name}>
              {userDetails.userEntity && (
                <WppListItem
                  key="userEntity"
                  onClick={() => {
                    setEntity({
                      name: userDetails.userEntity.Name,
                      id: userDetails.userEntity.ID,
                    });
                  }}
                  value={entity.name}
                >
                  <p slot="label">{userDetails.userEntity.Name}</p>
                </WppListItem>
              )}
            </WppSelect>
          </Grid>
          <Grid container className="checbox-container" item spacing={3} xs={12}>
            <Grid item>
              <WppCheckbox
                checked={welcomed}
                required
                labelConfig={{ text: "Welcomed" }}
                name="welcomed"
                onWppChange={({ detail: { checked } }) => {
                  setWelcomed(checked);
                }}
              />
            </Grid>
            <Grid item>
              <WppCheckbox
                checked={trained}
                required
                labelConfig={{ text: "Trained" }}
                name="trained"
                onWppChange={({ detail: { checked } }) => {
                  setTrained(checked);
                }}
              />
            </Grid>
          </Grid>
          <div className="error-container">
            {errorMessage !== "" && <WppInlineMessage size="s" type="error" message={errorMessage} />}
          </div>
          <Grid container className="form-btn" item md={15} spacing={1} justifyContent="flex-end">
            <Grid item>
              <WppButton className="cancel-btn" variant="secondary" onClick={handleCancel}>
                Cancel
              </WppButton>
            </Grid>
            <Grid item>
              <Button className="sign-in-btn fill-primary" type="submit" onClick={handleSubmit}>
                {variant === "approved" ? "Approve" : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default SaveUsersModal;
