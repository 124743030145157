import "./group-condition-editor.scss";

import type { SelectChangeEvent } from "@mui/material";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import type React from "react";
import { DeleteEditorButton } from "../../../../../components/delete-editor-button/delete-editor-button";
import { GroupCondition } from "../../../../../components/query-builder/models/group-condition";
import { DragType } from "../../../../../models/query-builder/drag-type";
import { Operator } from "../../../../../models/query-builder/operator";
import type { QueryCondition } from "../../../../../models/query-builder/query-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";
import { QueryConditionEditor } from "../query-condition-editor";
import { DragTarget } from "./components/drag-target";
import { OperatorSelect } from "./operator-select";
import { useState } from "react";

const chainedOperators = new Set([Operator.And, Operator.Or]);

interface IEditorOrEmptyProps {
  condition: QueryCondition | null;
  onEmptyDraggedOver: () => void;
  dragType: DragType;
}

function EditorOrEmpty({ condition, onEmptyDraggedOver, dragType }: IEditorOrEmptyProps): JSX.Element {
  if (condition !== null) {
    return <QueryConditionEditor condition={condition} />;
  }
  return <DragTarget onDraggedOver={onEmptyDraggedOver} dragType={dragType} />;
}

interface IDragRowProps {
  condition: QueryCondition;
  dragType: DragType;
  onConditionDraggedOver: (draggedOverCondition: QueryCondition, type: DragType) => void;
}

function DragRow({ condition, dragType, onConditionDraggedOver }: IDragRowProps): JSX.Element | null {
  const context = useCreateAudienceContext();
  const isDragTarget = condition.getIsDragTarget();
  const isRootCondition = condition.getParent() === null;
  const isDragTargetSelf = context.model.getConditionBeingDragged() === condition;
  if (isDragTarget && !isRootCondition && !isDragTargetSelf) {
    return (
      <Grid item xs={12}>
        <DragTarget
          onDraggedOver={() => {
            onConditionDraggedOver(condition, dragType);
          }}
          dragType={dragType}
        />
      </Grid>
    );
  }
  return null;
}

interface IGroupConditionEditorProps {
  condition: GroupCondition;
}

export function GroupConditionEditor({ condition }: IGroupConditionEditorProps): JSX.Element {
  const context = useCreateAudienceContext();
  const [isOperatorOnRemovalMode, setIsOperatorOnRemovalMode] = useState(false);
  const [isGroupOnRemovalMode, setIsGroupOnRemovalMode] = useState(false);

  const handleOperatorChanged = (event: SelectChangeEvent): void => {
    const typedOperator = event.target.value as Operator;
    condition.setOperator(typedOperator);
    context.onChange(condition);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    condition.setName(event.target.value);
    context.onChange(condition);
  };

  const isParentGroupWithSameOperator = (): boolean => {
    const thisOperator = condition.getOperator();

    if (!chainedOperators.has(thisOperator)) {
      return false;
    }

    const parent = condition.getParent();

    if (parent instanceof GroupCondition) {
      return parent.getOperator() === thisOperator;
    }

    return false;
  };

  const leftOperand = condition.getLeftOperand();
  const rightOperand = condition.getRightOperand();
  const isRootCondition = condition.getParent() === null;
  let groupClass = condition.getIsHighlighted()
    ? "editor-group-condition editor-group-condition-highlighted"
    : "editor-group-condition";

  const isChained = isParentGroupWithSameOperator();

  if (isChained) {
    groupClass = `${groupClass} no-indent`;
  }

  const deleteButton = (
    <DeleteEditorButton
      onDelete={() => {
        context.onDelete(condition);
      }}
      shouldHide={true}
      onMouseEnter={() => setIsGroupOnRemovalMode(true)}
      onMouseLeave={() => setIsGroupOnRemovalMode(false)}
    />
  );

  const incompleteConditionOperatorButton = (
    <Tooltip title="Remove logic operator">
      <IconButton size="small">
        <Grid item xs={3}>
          <DeleteEditorButton
            onDelete={() => {
              condition.isConditionEmpty()
                ? context.onDelete(condition)
                : context.onGroupConvertedToCondition(condition);
            }}
            shouldHide={false}
            onMouseEnter={() => setIsOperatorOnRemovalMode(true)}
            onMouseLeave={() => setIsOperatorOnRemovalMode(false)}
          />
        </Grid>
      </IconButton>
    </Tooltip>
  );

  const dragAbove = (
    <DragRow condition={condition} dragType={DragType.Above} onConditionDraggedOver={context.onConditionDraggedOver} />
  );

  const dragBelow = (
    <DragRow condition={condition} dragType={DragType.Below} onConditionDraggedOver={context.onConditionDraggedOver} />
  );

  const nameField = (
    <TextField
      id={`${condition.NAME}-name-input`}
      className="group-name-input"
      variant="standard"
      value={condition.getName()}
      onChange={handleNameChange}
      sx={{ visibility: isRootCondition ? "hidden" : "", display: isChained ? "none" : null }}
    />
  );

  const editorLeft = (
    <EditorOrEmpty
      condition={leftOperand}
      onEmptyDraggedOver={() => {
        context.onConditionDraggedOver(condition, DragType.LeftOperand);
      }}
      dragType={DragType.LeftOperand}
    />
  );

  const editorRight = (
    <EditorOrEmpty
      condition={rightOperand}
      onEmptyDraggedOver={() => {
        context.onConditionDraggedOver(condition, DragType.RightOperand);
      }}
      dragType={DragType.RightOperand}
    />
  );

  const operator = <OperatorSelect handleOperatorChanged={handleOperatorChanged} condition={condition} />;

  return (
    <div
      className={isGroupOnRemovalMode ? `editor-removal-mode ${groupClass}` : `${groupClass}`}
      draggable
      onMouseEnter={() => {
        context.onHighlight(condition);
      }}
      onMouseLeave={() => {
        context.onRemoveHighlight(condition);
      }}
      onDragEnd={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionDroppedOnto(condition);
      }}
      onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionPickedUp(condition);
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        context.onConditionDraggedOver(condition, DragType.OnTop);
      }}
    >
      {!isChained && deleteButton}
      <Grid container>
        {leftOperand && dragAbove}
        <Grid item xs={8}>
          {nameField}
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right", position: "relative" }}></Grid>
        <Grid item xs={12}>
          {editorLeft}
        </Grid>
        <Grid
          item
          xs={12}
          className={`${isOperatorOnRemovalMode ? "editor-removal-mode operator" : "operator"}`}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {operator}
          {condition.isConditionIncomplete() && incompleteConditionOperatorButton}
        </Grid>
        <Grid item xs={12}>
          {editorRight}
        </Grid>
        {rightOperand && dragBelow}
      </Grid>
    </div>
  );
}
