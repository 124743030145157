import "./styles.scss";

import type { QueryBuilderModel } from "../../../../../../../components/query-builder/models/query-builder-model";
import type { VariableData } from "../../../../../store/variables";
import { useVariable } from "../../../../../store/variables";
import { VariableQueryBuilder } from "./components/variable-query-builder";
interface IVariableCardProps {
  variableId: VariableData["id"];
}

export function VariableCard({ variableId }: IVariableCardProps): JSX.Element {
  const variableDefinition: QueryBuilderModel = useVariable(variableId).variableDefinition;

  return (
    <div className="variable-container">
      <VariableQueryBuilder variableId={variableId} condition={variableDefinition.rootCondition} />
    </div>
  );
}
