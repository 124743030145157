import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteEditorButton } from "../../../../../components/delete-editor-button/delete-editor-button";
import { MultipleValueEditor } from "../../../../../components/editors/multiple";
import type { MultiRangeCondition } from "../../../../../components/query-builder/models/multi-range-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";
import { useState } from "react";

interface IMultipleConditionEditor {
  condition: MultiRangeCondition;
}

export function MultiRangeConditionEditor({ condition }: IMultipleConditionEditor): JSX.Element {
  const context = useCreateAudienceContext();
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  const handleStateSelected = (state: string[]) => {
    condition.setValue(state);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className={`${isOnRemovalMode ? "editor-removal-mode editor-state-condition" : "editor-state-condition"}`}>
      <DeleteEditorButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        shouldHide={true}
        onMouseEnter={() => setIsOnRemovalMode(true)}
        onMouseLeave={() => setIsOnRemovalMode(false)}
      />
      <MultipleValueEditor
        currentValue={condition.getValue()}
        options={Object.keys(condition.getAllowedValues())}
        valueName={"Age Groups"}
        onOptionChosen={handleStateSelected}
        error={error}
        showPaperComponents={false}
      />
    </div>
  );
}
