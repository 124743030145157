import "./audience-condition-editor.scss";

import { Alert, CircularProgress, Stack, Tooltip } from "@mui/material";
import type { IAudienceOverview } from "@vmlyr/appserviceshared/dist/models/audience-overview";
import Humanize from "humanize-plus";
import { useEffect, useState } from "react";
import { ReactComponent as AudienceIcon } from "../../../../../../assets/icon-audience.svg";
import { DeleteEditorButton } from "../../../../../../components/delete-editor-button/delete-editor-button";
import type { AudienceCondition } from "../../../../../../components/query-builder/models/audience-condition";
import type { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import { getAudienceOverview } from "../../../../../../connekd-api/audiences/get-overview";
import { useGroup, useGroupsActions } from "../../../../store/groups";

const constructTooltipMessage = (overview: IAudienceOverview): string =>
  `This condition is an audience of ${Humanize.formatNumber(overview.size || 0)} individuals, created by ${overview.owner}`;

interface Props {
  condition: AudienceCondition;
  groupId: number;
}

export function AudienceConditionEditor({ condition, groupId }: Props): JSX.Element {
  const [overview, setOverview] = useState<IAudienceOverview | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  const { removeGroupDefinition } = useGroupsActions();

  const hasLoaded = !isLoading && overview !== null;

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage(null);

    getAudienceOverview(condition.friendlyId)
      .then((data) => {
        setOverview(data);
      })
      .catch((err) => {
        setErrorMessage(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [condition.friendlyId]);

  let definition: QueryBuilderModel | null;
  if (groupId !== undefined) {
    definition = useGroup(groupId).definition;
  }

  const handleDelete = (): void => {
    if (definition == null) {
      console.error("Definition is undefined or null");
    } else {
      removeGroupDefinition(condition, definition);
    }
  };

  const deleteButton = (
    <DeleteEditorButton
      onDelete={handleDelete}
      shouldHide={true}
      onMouseEnter={() => setIsOnRemovalMode(true)}
      onMouseLeave={() => setIsOnRemovalMode(false)}
    />
  );

  const tooltip = hasLoaded && (
    <Tooltip title={constructTooltipMessage(overview!)}>
      <span className="audience-name">
        {overview!.name} <AudienceIcon className="audience-icon" />
      </span>
    </Tooltip>
  );

  const spinner = isLoading && (
    <Stack direction="row" spacing={2} alignItems="center">
      <CircularProgress size="1.5rem" />
      <span className="audience-loading-message">Loading audience...</span>
    </Stack>
  );

  const errorAlert = errorMessage !== null && <Alert severity="error">{errorMessage}</Alert>;

  return (
    <div
      className={`${isOnRemovalMode ? "editor-removal-mode editor-audience-condition" : "editor-audience-condition"}`}
    >
      {deleteButton}
      {tooltip}
      {spinner}
      {errorAlert}
    </div>
  );
}
