import { AudienceDefinitionData } from "../../../models/query-builder/audience-definition-data";
import type { DragType } from "../../../models/query-builder/drag-type";
import { Operator } from "../../../models/query-builder/operator";
import { QueryCondition } from "../../../models/query-builder/query-condition";
import { ConditionValidationState } from "../../../models/validation-state";

export class GroupCondition extends QueryCondition {
  public readonly NAME: string = "GroupCondition";

  constructor(
    parent: QueryCondition | null = null,
    private groupName: string = "New Group",
    private leftOperand: QueryCondition | null = null,
    private rightOperand: QueryCondition | null = null,
    private operator: Operator = Operator.And,
  ) {
    super(parent);
    leftOperand?.setParent(this);
    rightOperand?.setParent(this);
  }

  getName(): string {
    return this.groupName;
  }

  setName(newName: string): void {
    this.groupName = newName;
  }

  getLeftOperand(): QueryCondition | null {
    return this.leftOperand;
  }

  setLeftOperand(newOperand: QueryCondition | null): void {
    this.leftOperand = newOperand;
    newOperand?.setParent(this);
  }

  getRightOperand(): QueryCondition | null {
    return this.rightOperand;
  }

  setRightOperand(newOperand: QueryCondition | null): void {
    this.rightOperand = newOperand;
    newOperand?.setParent(this);
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(newOperator: Operator): void {
    this.operator = newOperator;
  }

  setHighlight(highlightedCondition: QueryCondition): void {
    this.isHighlighted = highlightedCondition === this;
    this.leftOperand?.setHighlight(highlightedCondition);
    this.rightOperand?.setHighlight(highlightedCondition);
  }

  setIsDragTarget(dragTargetCondition: QueryCondition | null, type: DragType | null): void {
    if (dragTargetCondition === this) {
      this.isDragTarget = true;
      this.dragType = type;
    } else {
      this.isDragTarget = false;
      this.dragType = null;
    }
    this.leftOperand?.setIsDragTarget(dragTargetCondition, type);
    this.rightOperand?.setIsDragTarget(dragTargetCondition, type);
  }

  getIsDragTarget(): boolean {
    return this.isDragTarget;
  }

  // override
  setDraggedCondition(beingDragged: QueryCondition | null) {
    this.isBeingDragged = beingDragged === this;
    this.leftOperand?.setDraggedCondition(beingDragged);
    this.rightOperand?.setDraggedCondition(beingDragged);
  }

  getValidationState(): ConditionValidationState {
    if (!(this.getLeftOperand() && this.getRightOperand())) {
      return new ConditionValidationState(false, `The group condition "${this.getName()}" must have two operands`);
    }
    if (!this.getOperator()) {
      return new ConditionValidationState(false, `The group condition ${this.getName()} must have an operator`);
    }
    const leftOperandValidationState = this.getLeftOperand()!.getValidationState();
    const rightOperandValidationState = this.getRightOperand()!.getValidationState();

    if (!leftOperandValidationState.isValid) {
      return new ConditionValidationState(
        false,
        `The group condition ${this.getName()} has the following validation error on its first operand: ${
          leftOperandValidationState.invalidReason
        }`,
      );
    }

    if (!rightOperandValidationState.isValid) {
      return new ConditionValidationState(
        false,
        `The group condition ${this.getName()} has the following validation error on its second operand: ${
          rightOperandValidationState.invalidReason
        }`,
      );
    }

    return new ConditionValidationState(true);
  }

  isConditionIncomplete(): boolean {
    return !this.getLeftOperand() || !this.getRightOperand();
  }

  isConditionEmpty(): boolean {
    return !this.getLeftOperand() && !this.getRightOperand();
  }

  // override
  getConditionBeingDragged(): QueryCondition | null {
    if (this.getIsBeingDragged()) {
      return this;
    }
    const leftOperandBeingDragged = this.getLeftOperand()?.getConditionBeingDragged();
    const rightOperandBeingDragged = this.getRightOperand()?.getConditionBeingDragged();
    if (leftOperandBeingDragged) {
      return leftOperandBeingDragged;
    }
    if (rightOperandBeingDragged) {
      return rightOperandBeingDragged;
    }
    return null;
  }

  // override
  getDraggedOverCondition(): QueryCondition | null {
    if (this.getIsDragTarget()) {
      return this;
    }
    const leftOperandDragTarget = this.getLeftOperand()?.getDraggedOverCondition();
    const rightOperandDragTarget = this.getRightOperand()?.getDraggedOverCondition();
    if (leftOperandDragTarget) {
      return leftOperandDragTarget;
    }
    if (rightOperandDragTarget) {
      return rightOperandDragTarget;
    }
    return null;
  }

  isParentOf(potentialChild: QueryCondition): boolean {
    const isLeftOperand = potentialChild === this.getLeftOperand();
    const isRightOperand = potentialChild === this.getRightOperand();

    if (isLeftOperand || isRightOperand) {
      return true;
    }

    const isLeftOperandParent = this.getLeftOperand()?.isParentOf(potentialChild);
    const isRightOperandParent = this.getRightOperand()?.isParentOf(potentialChild);

    if (isLeftOperandParent || isRightOperandParent) {
      return true;
    }

    return false;
  }

  getConditionData(): AudienceDefinitionData {
    return new AudienceDefinitionData(
      this.leftOperand?.getConditionData(),
      this.rightOperand?.getConditionData(),
      this.operator,
      this.groupName,
      "group",
    );
  }
}
