import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteEditorButton } from "../../../../../components/delete-editor-button/delete-editor-button";
import type { IRange } from "../../../../../components/editors/range";
import { RangeValueEditor } from "../../../../../components/editors/range";
import type { RangeCondition } from "../../../../../components/query-builder/models/range-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";
import { useState } from "react";

interface Props {
  condition: RangeCondition;
}

export function RangeConditionEditor({ condition }: Props): JSX.Element {
  const context = useCreateAudienceContext();
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  const handleAgeRangeSelected = (range: IRange): void => {
    condition.setRangeLowerBound(range.lowerBound);
    condition.setRangeUpperBound(range.upperBound);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className={`${isOnRemovalMode ? "editor-removal-mode editor-age-condition" : "editor-age-condition"}`}>
      <DeleteEditorButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        shouldHide={true}
        onMouseEnter={() => setIsOnRemovalMode(true)}
        onMouseLeave={() => setIsOnRemovalMode(false)}
      />
      <RangeValueEditor
        valueName="Age"
        lowerRange={condition.getRangeLowerBound()}
        upperRange={condition.getRangeUpperBound()}
        onRangeChosen={handleAgeRangeSelected}
        maxAllowed={condition.getMaximumAllowed()}
        minAllowed={condition.getMinimumAllowed()}
        error={error}
      />
    </div>
  );
}
