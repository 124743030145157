import "./styles.scss";

import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { ReactComponent as MoreIcon } from "../../../../../../../assets/icon-more.svg";
import { useVariablesActions } from "../../../../../store/variables";
import { useState } from "react";

export function VariablesWrapperHeader(): JSX.Element {
  const { removeAllVariables } = useVariablesActions();
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const open = Boolean(menuElement);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuElement(event.currentTarget);
  };

  const handleClose = () => {
    setMenuElement(null);
  };

  return (
    <Grid className="project-variable-header" container>
      <Grid className="header-column-right" item xs={12}>
        <Button
          size="small"
          variant="text"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disableRipple
        >
          <MoreIcon />
        </Button>
        <Menu
          className="project-variable-options"
          anchorEl={menuElement}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            className="project-variable-options project-variable-option-warning"
            onClick={() => {
              removeAllVariables();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
