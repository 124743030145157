"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupIdForConnekdGroup = getGroupIdForConnekdGroup;
exports.convertOktaGroupToConnekdGroup = convertOktaGroupToConnekdGroup;
const okta_group_1 = require("./okta-group");
function getGroupIdForConnekdGroup(connekdGroup) {
    switch (connekdGroup) {
        case "ford": {
            return 1;
        }
        case "demo": {
            return 2;
        }
        default: {
            throw new Error(`unrecognised connekd group: ${connekdGroup}`);
        }
    }
}
function convertOktaGroupToConnekdGroup(oktaGroup) {
    switch (oktaGroup) {
        case okta_group_1.OKTA_HUB_DEMO:
        case okta_group_1.OKTA_DEV_DEMO:
        case okta_group_1.OKTA_HUB_DEMO_TEST:
        case okta_group_1.OKTA_DEV_DEMO_TEST: {
            return "demo";
        }
        case okta_group_1.OKTA_HUB_FORD:
        case okta_group_1.OKTA_DEV_FORD:
        case okta_group_1.OKTA_HUB_FORD_TEST:
        case okta_group_1.OKTA_DEV_FORD_TEST: {
            return "ford";
        }
        default: {
            // NOTE: maybe we should drop unrecognised users into a DEMO instance?
            throw new Error(`Did not recognise Okta group name: ${oktaGroup}`);
        }
    }
}
