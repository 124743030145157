import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { EmptyCondition } from "../../../components/query-builder/models/empty-condition";
import { QueryBuilderModel } from "../../../components/query-builder/models/query-builder-model";

export interface VariableData {
  id: number;
  variableDefinition: QueryBuilderModel;
}

interface State {
  variables: VariableData[];
  variablesDropTarget: VariableData["id"] | null;
  nextId: number;
  hasUnsavedChanges: boolean;
}

interface Actions {
  resetVariables: () => void;
  overrideVariables: (variables: State["variables"]) => void;

  addVariable: () => void;
  clearEmptyVariables: () => void;
  updateVariableDefinition: (id: VariableData["id"], newDefinition: QueryBuilderModel) => void;
  removeVariable: (id: VariableData["id"]) => void;
  removeAllVariables: () => void;

  setVariablesDropTarget: (id: VariableData["id"]) => void;
  clearVariablesDropTarget: () => void;
  haveVariablesSelectionChanged: (value: boolean) => void;
}

const initialVariable = {
  id: 0,
  variableDefinition: new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, ""),
};

export type VariableState = State & { actions: Actions };

const useVariablesStore = create(
  devtools<VariableState>((set) => ({
    variables: [initialVariable],

    variablesDropTarget: null,

    nextId: 1,

    hasUnsavedChanges: false,

    actions: {
      resetVariables: () => {
        set(() => ({
          variablesDropTarget: null,
          variables: [initialVariable],
          hasUnsavedChanges: false,
          nextId: 1,
        }));
      },

      overrideVariables: (newVariables: State["variables"]) => {
        set(() => {
          const nextId = newVariables.map((x) => x.id).reduce((acc, x) => Math.max(acc, x)) + 1;
          return {
            variables: newVariables,
            nextId,
            hasUnsavedChanges: false,
          };
        });
      },

      updateVariableDefinition: (id: VariableData["id"], newDefinition: QueryBuilderModel) => {
        set((state: VariableState) => {
          const newVariable = state.variables.map((e) =>
            e.id === id ? { ...e, variableDefinition: newDefinition } : e,
          );

          return {
            variables: newVariable,
            hasUnsavedChanges: true,
          };
        });
      },

      setVariablesDropTarget: (id: VariableData["id"]) => {
        set(() => ({ variablesDropTarget: id }));
      },

      clearVariablesDropTarget: () => {
        set(() => ({ variablesDropTarget: null }));
      },

      addVariable: () => {
        set((state) => {
          const interleavedVariablesTemporaryState = state.variables.reduce(
            (interleavedList: VariableData[], currentVariable: VariableData, index: number) => {
              interleavedList.push({
                id: state.nextId + index + 1,
                variableDefinition: new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, ""),
              });
              interleavedList.push(currentVariable);
              return interleavedList;
            },
            [],
          );

          return {
            variables: [
              ...interleavedVariablesTemporaryState,
              {
                id: state.nextId,
                variableDefinition: new QueryBuilderModel(new EmptyCondition(), "", [], "", "", null, ""),
              },
            ],
            nextId: state.nextId + state.variables.length + 1,
            hasUnsavedChanges: true,
          };
        });
      },

      removeAllVariables: () => {
        set(() => ({
          variables: [],
          hasUnsavedChanges: true,
        }));
      },

      removeVariable: (variableId: VariableData["id"]) => {
        set((state) => ({
          variables: state.variables.filter((dataItem) => dataItem.id !== variableId),
          hasUnsavedChanges: true,
        }));
      },

      clearEmptyVariables: () => {
        set((state) => {
          const notEmptyVariables = state.variables.filter(
            (dataItem) => !(dataItem.variableDefinition.rootCondition instanceof EmptyCondition),
          );
          return {
            variables: notEmptyVariables.length === 0 ? [initialVariable] : notEmptyVariables,
            hasUnsavedChanges: true,
          };
        });
      },

      haveVariablesSelectionChanged: (value: boolean) => {
        set(() => ({
          hasUnsavedChanges: value,
        }));
      },
    },
  })),
);

export const useVariablesActions = (): Actions => useVariablesStore((state) => state.actions);

export const useVariableHasUnsavedChanges = (): State["hasUnsavedChanges"] =>
  useVariablesStore((state) => state.hasUnsavedChanges);

export const useVariableIsValid = (): boolean =>
  useVariablesStore((state) => {
    const isValid = state.variables
      .map((variable) => variable.variableDefinition.rootCondition.getValidationState().isValid)
      .every(Boolean);

    return state.variables.length > 0 && isValid;
  });

export const useIsVariableSectionInInitialState = (): boolean =>
  useVariablesStore(
    (state) =>
      state.variables.length === 1 && state.variables[0].variableDefinition.rootCondition instanceof EmptyCondition,
  );

export const useVariablesData = (): State["variables"] => useVariablesStore((state) => state.variables);

export const useVariablesDropTarget = (): State["variablesDropTarget"] =>
  useVariablesStore((state) => state.variablesDropTarget);

export const useVariable = (id: VariableData["id"]): VariableData => {
  const variables = useVariablesData();
  const result = variables.find((x) => x.id === id);

  if (result === undefined) {
    throw new Error("invalid group id");
  }

  return result;
};

// TODO: consider removing this and using `useVariablesData`
export const readVariables = (): State["variables"] => useVariablesStore.getState().variables;
