import type { QueryCondition } from "../../../models/query-builder/query-condition";

export class SelectableCondition {
  constructor(
    public readonly name: string,
    public readonly id: string,
    public isVisible: boolean,
    public isVisibleInitially: boolean,
    public draggedObject: (() => QueryCondition) | null,
    public dimensionAttributes?: string,
  ) {}
}
