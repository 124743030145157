import "./request-access-form.scss";

import { Button, Container, Grid, InputLabel } from "@mui/material";
import { WppInlineMessage, WppTextInput } from "@platform-ui-kit/components-library-react";
import regex from "@vmlyr/common/dist/constants/regex";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfigurationHelper from "../../../helpers/configuration-helper";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface ISetAccessRequest {
  setToggleConfirmation: any;
}

// eslint-disable-next-line
const RequestAccessForm: React.FC<ISetAccessRequest> = ({ setToggleConfirmation }) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const nameRef = useRef<HTMLInputElement | null>(null);
  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const jobRef = useRef<HTMLInputElement | null>(null);
  const companyRef = useRef<HTMLInputElement | null>(null);
  const clientAccountRef = useRef<HTMLInputElement | null>(null);

  interface IUserData {
    first_name: string;
    last_name: string;
    email: string;
    job_title: string;
    company: string;
    client_account: string;
    token: string;
  }

  useEffect(() => {
    // Add reCaptcha API JS
    const script = document.createElement("script");
    script.src = `${ConfigurationHelper.GetRecaptchaApiUrl()}?render=${ConfigurationHelper.GetRecaptchaSiteKey()}`;
    document.body.append(script);
  }, []);

  async function postAccessRequest(userData: IUserData): Promise<any> {
    try {
      await fetch(ConfigurationHelper.CreateUserEndpoint(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      })
        .then(async (response) => await response.json())
        .then((data) => {
          if (data.status === "success") {
            setToggleConfirmation(true);
          } else {
            setErrorMessage(`${data.message}`);
          }
          return data;
        });
    } catch (error) {
      return error;
    }
  }

  const refCheck = (ref: any, extraCondition: any = /./) => {
    if (
      ref?.current.querySelector(".wppTextInput")?.shadowRoot &&
      ref.current.querySelector(".wppTextInput").shadowRoot.querySelector("input").value.length > 0 &&
      extraCondition.test(ref.current.querySelector(".wppTextInput").shadowRoot.querySelector("input").value)
    ) {
      return ref.current.querySelector(".wppTextInput").shadowRoot.querySelector("input").value;
    }
    setErrorMessage(
      `Please ensure that ${ref.current.querySelector(".wppTextInput").id.replace("-", " ")} is filled in correctly.`,
    );
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMessage("");
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(ConfigurationHelper.GetRecaptchaSiteKey(), { action: "request_access" })
        .then((token: any) => {
          if (
            refCheck(nameRef) &&
            refCheck(lastNameRef) &&
            refCheck(emailRef, regex.emailValidation) &&
            refCheck(jobRef) &&
            refCheck(companyRef) &&
            refCheck(clientAccountRef)
          ) {
            postAccessRequest({
              first_name: refCheck(nameRef),
              last_name: refCheck(lastNameRef),
              email: refCheck(emailRef, regex.emailValidation),
              job_title: refCheck(jobRef),
              company: refCheck(companyRef),
              client_account: refCheck(clientAccountRef),
              token,
            })
              .then(() => {
                navigate("/access-pending");
              })
              .catch((error) => {
                setErrorMessage(error);
              });
          }
        })
        .catch((err: any) => {
          setErrorMessage(err);
        });
    });
  };

  return (
    <Container className="form-container" maxWidth="sm">
      <div className="form-container-inner">
        <h2>Request Access</h2>
        <Grid container spacing={2} alignItems="center">
          <Grid ref={nameRef} item xs={12}>
            <InputLabel className="label" shrink>
              First name
            </InputLabel>
            <WppTextInput class="wppTextInput" id="first-name" />
          </Grid>

          <Grid ref={lastNameRef} item xs={12}>
            <InputLabel className="label" shrink>
              Last name
            </InputLabel>
            <WppTextInput class="wppTextInput" id="last-name" />
          </Grid>

          <Grid ref={emailRef} item xs={12}>
            <InputLabel className="label" shrink>
              Email
            </InputLabel>
            <WppTextInput class="wppTextInput" id="email" />
          </Grid>

          <Grid ref={jobRef} item xs={12}>
            <InputLabel className="label" shrink>
              Job title
            </InputLabel>
            <WppTextInput class="wppTextInput" id="job-title" />
          </Grid>

          <Grid ref={companyRef} item xs={12}>
            <InputLabel className="label" shrink>
              Company
            </InputLabel>
            <WppTextInput class="wppTextInput" id="company" />
          </Grid>

          <Grid ref={clientAccountRef} item xs={12}>
            <InputLabel className="label" shrink>
              Client Account
            </InputLabel>
            <WppTextInput class="wppTextInput" id="client-account" />
          </Grid>
          <Grid className="form-btn" item md={12} sx={{ textAlign: "center" }}>
            <Button className="sign-in-btn fill-primary" type="submit" onClick={handleSubmit}>
              Request access
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="error-container">
        {errorMessage !== "" && <WppInlineMessage size="s" type="error" message={errorMessage} />}
      </div>
    </Container>
  );
};

export default RequestAccessForm;
