import "./empty-condition-editor.scss";
import type { EmptyCondition } from "../../../../../../../components/query-builder/models/empty-condition";
import { useProjectConditionType } from "../../../../../store/projects";
import { useIsVariableSectionInInitialState } from "../../../../../store/variables";

interface IEmptyConditionEditorProps {
  condition: EmptyCondition;
  isVariableContext?: boolean;
}

export function EmptyConditionEditor({
  condition,
  isVariableContext = false,
}: IEmptyConditionEditorProps): JSX.Element {
  const conditionType: string = useProjectConditionType();
  const isVariableSectionInInitialState = useIsVariableSectionInInitialState();

  const canDrop = isVariableContext && conditionType === "AudienceCondition";

  const dragTargetClass = condition.getIsDragTarget() ? "highlightOk" : "";
  const audienceClass = condition.getIsDragTarget() ? "highlightDanger" : "";

  const getLabel = () => {
    if (!isVariableSectionInInitialState && isVariableContext) {
      return "drop here";
    }

    return "Drop component";
  };

  const getStyle = () => {
    if (!isVariableSectionInInitialState && isVariableContext) {
      return "query-builder-variable-dropzone";
    }

    return "editor-empty-project-condition";
  };

  return (
    <div className={`${getStyle()} ${canDrop ? audienceClass : dragTargetClass}`}>
      <span className="in-border-text">{getLabel()}</span>
    </div>
  );
}
