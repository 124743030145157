import { getWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import { addQueryParams } from "@vmlyr/appserviceshared/dist/helpers/url-helper";
import type { Profiles } from "@vmlyr/connekdfordshared/dist/models/api/audience-profiles";
import ConfigurationHelper from "../../helpers/configuration-helper";

interface IResponse {
  data: Profiles[];
  count: number;
}
const PAGE_SIZE = 100;

export async function getFilteredProfilesList(
  page: number,
  searchText?: string,
  pageSize: number = PAGE_SIZE,
): Promise<IResponse> {
  let searchUrl = `${ConfigurationHelper.getConnekdApiEndpoint()}/audience-profiles`;
  searchUrl = addQueryParams(searchUrl, `page=${page}`, `pageSize=${pageSize}`);

  if (searchText) {
    searchUrl = addQueryParams(searchUrl, `searchText=${searchText.toLowerCase()}`);
  }

  const response = await getWithToken<IResponse>(searchUrl);

  return response;
}
