import React from "react";
import "./multiple-value-dropdown.scss";
import type { AutocompleteChangeReason, AutocompleteRenderInputParams } from "@mui/material";
import { Autocomplete, Checkbox, Divider, FormControlLabel, Grid, InputLabel, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

interface Props {
  options: string[];
  valueName: string;
  onOptionChosen: any;
  currentValue: any;
  error: JSX.Element | null;
  showPaperComponents: boolean;
}

export function MultipleValueEditor(props: Props): JSX.Element {
  const { options, valueName, onOptionChosen, currentValue, error, showPaperComponents } = props;
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const inputId = `${valueName}_multi_input`;
  const inputLabelId = `${inputId}_multi_label`;

  const handleChange = (e: React.SyntheticEvent, value: string[] | string, reason: AutocompleteChangeReason): void => {
    if (reason === "clear" || reason === "removeOption") setSelectAll(false);
    if (reason === "selectOption" && value.length === options.length) setSelectAll(true);
    onOptionChosen(value);
  };

  const trimmedOptions = options.filter((option: string) => option !== null);

  const handleToggleSelectAll = (): void => {
    setSelectAll((prev: boolean) => {
      if (prev) {
        onOptionChosen([]);
      } else {
        onOptionChosen([...trimmedOptions]);
      }
      return !prev;
    });
  };

  const customPaper = React.useCallback((paperProps: any) => {
    const { children, ...restPaperProps } = paperProps;
    return (
      <Paper {...restPaperProps}>
        {showPaperComponents && (
          <div>
            <Box
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              pl={1.5}
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                  handleToggleSelectAll();
                }}
                label="Select all"
                control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
              />
            </Box>
            <Divider />
          </div>
        )}

        {children}
      </Paper>
    );
  }, []);

  return (
    <div className="editor-multiple-control">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <InputLabel id={inputLabelId} className={"editor-multiple-label"}>
            {valueName}
          </InputLabel>
        </Grid>

        <Grid item>
          <InputLabel>is any of</InputLabel>
        </Grid>

        <Grid
          item
          sx={{
            width: "100%",
          }}
        >
          <Autocomplete
            options={trimmedOptions}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                flexWrap: "wrap",
                width: "100%",
              },
              "& .MuiAutocomplete-inputRoot:hover .MuiAutocomplete-input, & .MuiAutocomplete-input:focus": {
                minWidth: "30px",
              },
            }}
            multiple
            id={inputId}
            size="small"
            value={currentValue}
            onChange={handleChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label="Multiple options" />}
            PaperComponent={customPaper}
          />
        </Grid>

        {error !== null && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
