import "./styles.scss";

import type React from "react";
import { conditionDragged } from "../../../../../../../../../components/query-builder/events-handlers/condition-dragged";
import { AudienceCondition } from "../../../../../../../../../components/query-builder/models/audience-condition";
import { CustomAudienceCondition } from "../../../../../../../../../components/query-builder/models/custom-audience-condition";
import { DiscreetCondition } from "../../../../../../../../../components/query-builder/models/discreet-condition";
import { EmptyCondition } from "../../../../../../../../../components/query-builder/models/empty-condition";
import { MultiRangeCondition } from "../../../../../../../../../components/query-builder/models/multi-range-condition";
import { MultipleCondition } from "../../../../../../../../../components/query-builder/models/multiple-condition";
import { QueryBuilderModel } from "../../../../../../../../../components/query-builder/models/query-builder-model";
import { RangeCondition } from "../../../../../../../../../components/query-builder/models/range-condition";
import { DragType } from "../../../../../../../../../models/query-builder/drag-type";
import type { QueryCondition } from "../../../../../../../../../models/query-builder/query-condition";
import type { VariableData } from "../../../../../../../store/variables";
import { useVariable, useVariablesActions } from "../../../../../../../store/variables";
import { CustomAudienceConditionEditor } from "../../../../../query-builder/editors/custom-audience-condition-editor";
import { DiscreetConditionEditor } from "../../../../../query-builder/editors/discreet-condition-editor";
import { EmptyConditionEditor } from "../../../../../query-builder/editors/empty-condition/empty-condition-editor";
import { MultiRangeConditionEditor } from "../../../../../query-builder/editors/multi-range-condition-editor";
import { MultipleConditionEditor } from "../../../../../query-builder/editors/multiple-condition-editor";
import { RangeConditionEditor } from "../../../../../query-builder/editors/range-condition-editor";
import { Dispatch, SetStateAction, useState } from "react";

interface MouseEventsProps {
  condition: QueryCondition;
  variableId: VariableData["id"];
  children: JSX.Element;
}

function MouseEventsHandler(props: MouseEventsProps): JSX.Element {
  const { condition, children, variableId } = props;

  const { updateVariableDefinition, setVariablesDropTarget, clearVariablesDropTarget } = useVariablesActions();

  const isEmptyCondition = condition instanceof EmptyCondition;

  const variableDefinition: QueryBuilderModel = useVariable(variableId).variableDefinition;

  const onHighlight = (highlightedCondition: QueryCondition): void => {
    variableDefinition.highlight(highlightedCondition);
    updateVariableDefinition(variableId, QueryBuilderModel.fromExisting(variableDefinition));
  };

  const onRemoveHighlight = (highlightRemovedCondition: QueryCondition): void => {
    clearVariablesDropTarget();
    variableDefinition.removeHighlight(highlightRemovedCondition);
    updateVariableDefinition(variableId, QueryBuilderModel.fromExisting(variableDefinition));
    const newDefinition = conditionDragged(highlightRemovedCondition, variableDefinition);
    newDefinition.cancelDrag();
  };

  const onConditionDraggedOver = (draggedOverCondition: QueryCondition, type: DragType): void => {
    setVariablesDropTarget(variableId);
    const newModel = QueryBuilderModel.fromExisting(variableDefinition);
    newModel.setDraggedOverCondition(draggedOverCondition, type);
    updateVariableDefinition(variableId, newModel);
  };

  const onConditionDroppedOnto = (droppedOntoCondition: QueryCondition): void => {
    if (variableDefinition.getDraggedOverCondition() !== null) {
      const newDefinition = conditionDragged(droppedOntoCondition, variableDefinition);
      newDefinition.cancelDrag();
      updateVariableDefinition(variableId, newDefinition);
    }
  };

  const onConditionPickedUp = (pickedUpCondition: QueryCondition): void => {
    const newModel = QueryBuilderModel.fromExisting(variableDefinition);
    newModel.setConditionBeingDragged(pickedUpCondition);
    newModel.setDraggedOverCondition(pickedUpCondition.getParent()!, DragType.OnTop);
    updateVariableDefinition(variableId, newModel);
  };

  return (
    <div
      className={"editor variable-editor"}
      draggable
      onMouseEnter={() => {
        if (isEmptyCondition) onHighlight(condition);
      }}
      onMouseLeave={() => {
        if (isEmptyCondition) onRemoveHighlight(condition);
      }}
      onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (isEmptyCondition) onConditionPickedUp(condition);
      }}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (isEmptyCondition) onConditionDraggedOver(condition, DragType.OnTop);
      }}
      onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
      }}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (isEmptyCondition) onConditionDroppedOnto(condition);
      }}
    >
      {children}
    </div>
  );
}

function selectEditor(
  condition: QueryCondition,
  variableId: VariableData["id"],
  handleRemoveVariable: () => void,
): JSX.Element {
  if (condition instanceof EmptyCondition) {
    return <EmptyConditionEditor condition={condition} isVariableContext={true} />;
  }

  if (condition instanceof RangeCondition) {
    return <RangeConditionEditor condition={condition} variableId={variableId} onDelete={handleRemoveVariable} />;
  }

  if (condition instanceof DiscreetCondition && !condition.dimension.multiSql) {
    return <DiscreetConditionEditor condition={condition} variableId={variableId} onDelete={handleRemoveVariable} />;
  }

  if (
    condition instanceof MultipleCondition ||
    (condition instanceof DiscreetCondition && condition.dimension.multiSql)
  ) {
    return (
      <MultipleConditionEditor
        condition={condition as MultipleCondition}
        variableId={variableId}
        onDelete={handleRemoveVariable}
      />
    );
  }

  // this shouldn't be possible any more
  if (condition instanceof AudienceCondition) {
    throw new Error("This should no longer be possible");
    //   return <AudienceConditionEditor condition={condition} />;
  }

  if (condition instanceof MultiRangeCondition) {
    return (
      <MultiRangeConditionEditor
        condition={condition as MultiRangeCondition}
        variableId={variableId}
        onDelete={handleRemoveVariable}
      />
    );
  }

  if (condition instanceof CustomAudienceCondition) {
    return (
      <CustomAudienceConditionEditor condition={condition} variableId={variableId} onDelete={handleRemoveVariable} />
    );
  }

  throw new TypeError(`No condition editor was found for condition type ${condition.NAME}`);
}

interface Props {
  condition: QueryCondition;
  variableId: VariableData["id"];
}

export function VariableQueryBuilder(props: Props): JSX.Element {
  const { variableId, condition } = props;
  const { removeVariable } = useVariablesActions();

  const handleRemoveVariable = (): void => {
    removeVariable(variableId);
  };

  const editor = selectEditor(condition, variableId, handleRemoveVariable);

  return (
    <MouseEventsHandler variableId={variableId} condition={condition}>
      {editor}
    </MouseEventsHandler>
  );
}
