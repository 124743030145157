import "./styles.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

interface Props {
  onDelete: () => void;
  shouldHide?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function DeleteEditorButton({ onDelete, shouldHide, onMouseEnter, onMouseLeave }: Props): JSX.Element {
  return (
    <IconButton
      className={`${shouldHide ? "conditional-button delete-button" : "delete-button"}`}
      onClick={() => {
        onMouseLeave && onMouseLeave();
        onDelete();
      }}
      size="small"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ "&:hover": { backgroundColor: "transparent" } }}
    >
      <Close />
    </IconButton>
  );
}
