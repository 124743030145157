import "./custom-audience-condition-editor.scss";
import { Grid } from "@mui/material";
import type { AutocompleteRenderInputParams } from "@mui/material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { WppIconSearch, WppListItem, WppSelect } from "@platform-ui-kit/components-library-react";
import type { ICustomAudience, ISegments } from "@vmlyr/common/dist/models/custom-audience";
import { useEffect, useState } from "react";
import { DeleteEditorButton } from "../../../../../../components/delete-editor-button/delete-editor-button";
import type { CustomAudienceCondition } from "../../../../../../components/query-builder/models/custom-audience-condition";
import type { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import { useCustomAudiencesActions } from "../../../../../../stores/custom-audiences";
import { useCustomAudiences } from "../../../../../../stores/custom-audiences";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../../store/groups";
import { useVariable, useVariablesActions } from "../../../../store/variables";
import { useProjectRunActions } from "../../../../store/projects";

interface Props {
  condition: CustomAudienceCondition;
  groupId?: number;
  variableId?: number;
  onDelete?: () => void;
}

export function CustomAudienceConditionEditor(props: Props): JSX.Element {
  const { condition, groupId, variableId, onDelete } = props;

  const customAudiences: ICustomAudience[] | [] = useCustomAudiences();
  const { fetchCustomAudiences } = useCustomAudiencesActions();
  useGroupsNudge();
  const { nudgeState, removeGroupDefinition, hasGroupChanged } = useGroupsActions();
  const { haveVariablesSelectionChanged } = useVariablesActions();
  const { hasRun } = useProjectRunActions();
  const [filteredOptions, setFilteredOptions] = useState<unknown[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<any>(null);
  const [selectedDataSource, setSelectedDataSource] = useState<string | null>(null);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  const getCustomAudiences = async () => {
    if (customAudiences.length === 0) {
      await fetchCustomAudiences();
    }
  };

  let definition: QueryBuilderModel;

  if (groupId !== undefined) {
    const group = useGroup(groupId);
    definition = group.definition;
  } else if (variableId !== undefined) {
    definition = useVariable(variableId).variableDefinition;
  }

  useEffect(() => {
    getCustomAudiences();
  }, [props]);

  const sortedCustomAudiences = [...customAudiences].sort((a: ICustomAudience, b: ICustomAudience) =>
    a.dataSource.toLowerCase().localeCompare(b.dataSource.toLowerCase()),
  );

  useEffect(() => {
    if (sortedCustomAudiences.length === 1) {
      const defaultDataSourceDataSourceOptions = sortedCustomAudiences[0];
      setSelectedDataSource(defaultDataSourceDataSourceOptions.dataSource);
      setIsDisabled(false);

      const segmentOptions: ISegments[] = defaultDataSourceDataSourceOptions.segments.map((s: ISegments) => ({
        segmentId: s.segmentId,
        segmentName: s.segmentName,
      }));
      setFilteredOptions(segmentOptions);

      const selectedSegmentDetails: ISegments = defaultDataSourceDataSourceOptions.segments[0];

      if (defaultDataSourceDataSourceOptions.segments.length === 1) {
        const initialValue = {
          dataSource: defaultDataSourceDataSourceOptions.dataSource,
          segmentId: selectedSegmentDetails.segmentId,
          segmentName: selectedSegmentDetails.segmentName,
        };

        condition.setValue(initialValue);
        setAutocompleteValue(initialValue);
      }
    } else if (condition.getValue()) {
      const matchingDataSource = sortedCustomAudiences.find(
        (segment: ICustomAudience) => segment.dataSource === condition.getValue().dataSource,
      );
      const segmentOptions: ISegments[] = matchingDataSource
        ? matchingDataSource.segments.map((s: ISegments) => ({ segmentId: s.segmentId, segmentName: s.segmentName }))
        : [];
      setFilteredOptions(segmentOptions);
      setIsDisabled(false);
    }
  }, [condition]);

  const handleStateSelected = (dataSource: string, segment: ISegments) => {
    if (segment) {
      if (groupId !== undefined) {
        hasGroupChanged(true);
      } else if (variableId !== undefined) {
        haveVariablesSelectionChanged(true);
      }
      hasRun(false);
      condition.setValue({
        dataSource,
        segmentId: segment.segmentId,
        segmentName: segment.segmentName,
      });
    } else {
      console.error("Invalid segment:", segment);
    }
    nudgeState();
  };

  const handleDataSourceClick = (child: ICustomAudience): void => {
    const selectedSegment = sortedCustomAudiences.find(
      (segment: ICustomAudience) => segment.dataSource === child.dataSource,
    );
    const segmentOptions: ISegments[] = selectedSegment
      ? selectedSegment.segments.map((s: ISegments) => ({ segmentId: s.segmentId, segmentName: s.segmentName }))
      : [];

    setSelectedDataSource(child.dataSource);
    setIsDisabled(false);

    if (segmentOptions.length > 1) {
      setAutocompleteValue(null);
      condition.setValue(null);
      setOpenDialogue(true);
      setFilteredOptions(segmentOptions);
    }

    if (segmentOptions.length === 1) {
      const selectedSegmentOption = segmentOptions[0];
      setAutocompleteValue(selectedSegmentOption);
      setFilteredOptions(segmentOptions);
      handleStateSelected(child.dataSource, selectedSegmentOption);
    }
  };

  const getPlaceholderValue = () => {
    const conditionValue: ICustomAudience = condition.getValue();
    return conditionValue && conditionValue.dataSource ? conditionValue.dataSource : selectedDataSource || "Select";
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      id="segment-options"
      placeholder="Segment ID or name"
      InputProps={{
        ...params.InputProps,
        style: { fontSize: 13 },
        startAdornment: (
          <InputAdornment position="start">
            <WppIconSearch slot="icon-start" />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <div
      id="custom-audience-container-for-project"
      className={`${isOnRemovalMode ? "editor-removal-mode editor-audience-condition" : "editor-audience-condition"}`}
    >
      <Grid container spacing={2} alignItems={"center"}>
        <DeleteEditorButton
          onDelete={() => {
            if (onDelete) {
              onDelete();
              return;
            }

            removeGroupDefinition(condition, definition);
          }}
          shouldHide={true}
          onMouseEnter={() => setIsOnRemovalMode && setIsOnRemovalMode(true)}
          onMouseLeave={() => setIsOnRemovalMode && setIsOnRemovalMode(false)}
        />
        <div className="container">
          <Grid className="data-source-grid" item>
            <div className="field-label">
              <span className="bold-text">Data source</span> is
            </div>
            <div className="data-source-container">
              <WppSelect size="s" placeholder={getPlaceholderValue()}>
                {sortedCustomAudiences.map((child: ICustomAudience, index: number) => (
                  <WppListItem
                    key={index}
                    onClick={() => {
                      handleDataSourceClick(child);
                    }}
                  >
                    <div slot="label">{child.dataSource}</div>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
          </Grid>
          <Grid className="segment-id-grid" item>
            <div className="field-label segment-id-label">
              where <span className="bold-text">Segment ID</span> is
            </div>
            <div className="segment-container">
              <Autocomplete
                size="small"
                id="segment-container-options"
                open={openDialogue}
                onOpen={() => {
                  setOpenDialogue(true);
                }}
                onClose={() => {
                  setOpenDialogue(false);
                }}
                disabled={isDisabled}
                freeSolo={!openDialogue}
                options={filteredOptions}
                getOptionLabel={(option) => `${option.segmentName} - ${option.segmentId}`}
                value={condition.getValue() || autocompleteValue}
                onChange={(evt, value) => {
                  if (value) {
                    setAutocompleteValue(value);
                    const selectedDataSourceValue = selectedDataSource || condition.getValue().dataSource;
                    handleStateSelected(selectedDataSourceValue, value);
                  }
                }}
                renderInput={renderInput}
                ListboxProps={{ style: { maxHeight: 150 } }}
                renderOption={(props, option: ISegments) => (
                  <li {...props}>
                    <div>
                      <div className="segment-id">{option.segmentId}</div>
                      <div className="segment-name">{option.segmentName}</div>
                    </div>
                  </li>
                )}
              />
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
