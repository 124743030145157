import "./explore-page.scss";

import { Alert, Grid, Modal } from "@mui/material";
import { postWithToken } from "@vmlyr/appserviceshared/dist/helpers/api-helper";
import type { CreateProjectExportOutput } from "@vmlyr/common/types/connekd-api";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import ConfirmUserActionModal from "../../components/confirm-user-action-modal";
import { ControlHeader } from "../../components/control-header";
import { Loadable } from "../../components/loadable";
import { Tabber } from "../../components/tabber/Tabber";
import { SearchTypes, listProjects } from "../../connekd-api/projects/list";
import { createProjectExport } from "../../connekd-api/projects/start-export";
import ConfigurationHelper from "../../helpers/configuration-helper";
import { useExportsActions } from "../../stores/exports";
import { ExploreHeader } from "./components/header";
import { ProjectTable } from "./components/project-table";
import ProjectTableDefinition from "./components/project-table-model";
import { Search } from "./components/search";
import { SearchModel } from "./components/search/components/search-filters/models/search-model";
import type { IProjectAction, IProjectData } from "./models/projectModel";
import { ProjectActions } from "./project-actions";
import {
  useProjectHorizontalHighlight,
  useProjectIndexHighlight,
  useProjectShowFigureHighlights,
  useProjectVerticalHighlight,
} from "./store/projects";

function allowedByView(searchType: SearchTypes, project: IProjectData): boolean {
  switch (searchType) {
    case SearchTypes.All: {
      return true;
    }

    case SearchTypes.Active: {
      return !project.isArchived;
    }

    case SearchTypes.Inactive: {
      return project.isArchived;
    }

    default: {
      return false;
    }
  }
}

async function updateRemoteState(idsToDelete: string[], endpoint: string): Promise<void> {
  const response = await postWithToken(endpoint, { projectIds: idsToDelete });

  if (!response.ok) {
    throw new Error(await response.text());
  }
}

function lastUpdatedTime(project: IProjectData): number {
  return project.lastUpdated === null ? 0 : new Date(project.lastUpdated).getTime();
}

interface IStartProps {
  projectIds: Array<IProjectData["idFull"]>;
  showFigureHighlight: boolean;
  verticalEqualOrGreaterHighlight: number;
  horizontalEqualOrGreaterHighlight: number;
  indexEqualOrGreaterHighlight: number;
}

async function startExports({
  projectIds,
  showFigureHighlight,
  verticalEqualOrGreaterHighlight,
  horizontalEqualOrGreaterHighlight,
  indexEqualOrGreaterHighlight,
}: IStartProps): Promise<CreateProjectExportOutput[]> {
  return await Promise.all(
    projectIds.map(
      async (id: string) =>
        await createProjectExport({
          id,
          showFigureHighlight,
          verticalEqualOrGreaterHighlight,
          horizontalEqualOrGreaterHighlight,
          indexEqualOrGreaterHighlight,
        }),
    ),
  );
}

export function Explore(): JSX.Element {
  const [pageError, setPageError] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResultPage, setSearchResultPage] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState<IProjectData[]>([]);
  const [previousScrollHeight, setPreviousScrollHeight] = useState<number | null>();
  const [searchType, setSearchType] = useState<SearchTypes>(SearchTypes.Active);
  const [searchModel, setSearchModel] = useState<SearchModel>(new SearchModel());
  const [debouncedSearchModel, setDebouncedSearchModel] = useState<SearchModel | null>(null);
  const [projects, setProjects] = useState<IProjectData[] | []>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const verticalEqualOrGreaterHighlight = useProjectVerticalHighlight();
  const horizontalEqualOrGreaterHighlight = useProjectHorizontalHighlight();
  const indexEqualOrGreaterHighlight = useProjectIndexHighlight();
  const showFigureHighlight = useProjectShowFigureHighlights();

  const { addProjectExportId } = useExportsActions();

  const debounceTime = 200;

  const performProjectQuery = () => {
    setIsLoading(true);
    listProjects(searchResultPage, searchModel, searchType)
      .then((rawData) => {
        const dataResult = rawData.map((x) => ({
          ...x,
          effectiveDate: x.lastModified || x.created,
        }));
        if (searchResultPage === 0) {
          setProjects(dataResult);
        } else {
          const existingIds = new Set(projects.map((r: any) => r.id));
          const newResults = dataResult ? [...dataResult] : [];
          for (const result of dataResult) {
            if (!existingIds.has(result.id)) {
              newResults.push(result);
            }
          }
          setProjects(newResults);
        }
        setSearchResultPage(searchResultPage + 1);
      })
      .then(() => {
        if (projects.length > 0) {
          let newResults: any;
          if (newResults && newResults.length > 0) {
            setProjects(newResults);
            setSelectedProjects([]);
          } else if (selectedProjects.length > 0) {
            setProjects([]);
            setSelectedProjects([]);
          }
        }
      })
      .catch((err) => {
        setPageError(`An error occurred whilst retrieving the project list: ${err}`);
      })
      .finally(() => {
        setIsSearching(false);
        setIsLoading(false);
      });
  };

  const performSearch = (): void => {
    if (searchModel.getValidationState().isValid) {
      if (!searchModel.isEmpty()) {
        setIsLoading(true);
        setIsSearching(true);
      }
      performProjectQuery();
      if (previousScrollHeight) {
        window.scrollTo(0, previousScrollHeight);
        setPreviousScrollHeight(null);
      }
    }
  };

  useEffect(() => {
    performSearch();
  }, [debouncedSearchModel]);

  useEffect(() => {
    performProjectQuery();
  }, [searchType]);

  const handleProjectsSelected = (newSelectedProjects: IProjectData[]): void => {
    setSelectedProjects(newSelectedProjects);
  };

  // eslint-disable-next-line
  const launchSearch = useCallback(
    debounce((updatedModel: SearchModel) => {
      setDebouncedSearchModel(updatedModel);
    }, debounceTime),
    [],
  );

  const handleScroll = (isAtBottom: boolean, ref: React.RefObject<HTMLDivElement>): void => {
    if (isAtBottom) {
      setPreviousScrollHeight(ref.current?.offsetTop);
    }
  };

  const handleSearchTypeSelected = (newSearchType: SearchTypes): void => {
    setSearchType(newSearchType);
    setSearchResultPage(0);
    setSelectedProjects([]);
  };

  const handleSearchParamsInput = (newSearchParams: SearchModel): void => {
    if (newSearchParams.isEmpty()) {
      setSearchModel(new SearchModel());
      setProjects([]);
      setSearchResultPage(0);
    } else {
      const newModel = SearchModel.fromExisting(newSearchParams);
      setSearchResultPage(0);
      setSearchModel(newModel);
    }
    launchSearch(newSearchParams);
  };

  function deleteLocalState(idsToDelete: string[]): () => void {
    const previousItems = [...projects];
    const previousSelected = [...selectedProjects];

    setProjects(projects.filter((project) => !idsToDelete.includes(project.id)));
    setSelectedProjects([]);

    return () => {
      setProjects(previousItems);
      setSelectedProjects(previousSelected);
    };
  }

  function updateLocalState(action: IProjectAction): () => void {
    const previousItems = [...projects];
    const previousSelected = [...selectedProjects];

    const projectsToUpdate = projects.filter((r) => previousSelected.includes(r));
    projectsToUpdate.forEach(
      (r: IProjectData) => ((r.isArchived = action.value), (r.lastUpdated = new Date().toISOString())),
    );

    const sortedProjects = projects
      .filter((project) => allowedByView(searchType, project))
      .sort((a: IProjectData, b: IProjectData) => lastUpdatedTime(b) - lastUpdatedTime(a));

    setProjects(sortedProjects);
    setSelectedProjects([]);

    return () => {
      setProjects(previousItems);
      setSelectedProjects(previousSelected);
    };
  }

  const handleArchive = async (): Promise<void> => {
    const idsToModify = selectedProjects.map((project) => project.id);
    let rollback = () => {};
    if (searchType === SearchTypes.All) {
      rollback = updateLocalState({ property: "isArchived", value: true });
    } else if (searchType === SearchTypes.Active) {
      rollback = deleteLocalState(idsToModify);
    }

    try {
      await updateRemoteState(idsToModify, ConfigurationHelper.ArchiveProjectEndpoint());
    } catch (error) {
      setPageError(`An error occurred whilst archiving projects: ${error}`);
      rollback();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExport = async (): Promise<void> => {
    const idsToExport = selectedProjects.map((project) => project.id);

    try {
      const exportResponses = await startExports({
        projectIds: idsToExport,
        showFigureHighlight,
        verticalEqualOrGreaterHighlight,
        horizontalEqualOrGreaterHighlight,
        indexEqualOrGreaterHighlight,
      });

      for (const exportResponse of exportResponses) {
        addProjectExportId(exportResponse.exportId);
      }
    } catch (error) {
      setPageError(`An error occurred whilst exporting projects: ${error}`);
    }
  };

  const handleUnarchive = async (): Promise<void> => {
    const idsToModify = selectedProjects.map((project) => project.id);
    let rollback = () => {};
    if (searchType === SearchTypes.All) {
      rollback = updateLocalState({ property: "isArchived", value: false });
    } else if (searchType === SearchTypes.Inactive) {
      rollback = deleteLocalState(idsToModify);
    }

    try {
      await updateRemoteState(idsToModify, ConfigurationHelper.UnarchiveProjectEndpoint());
    } catch (error) {
      setPageError(`An error occurred whilst unarchiving projects: ${error}`);
      rollback();
    }
  };

  const performDeleteProject = async (): Promise<void> => {
    const idsToDelete = selectedProjects.map((project) => project.id);
    const rollback = deleteLocalState(idsToDelete);
    try {
      await updateRemoteState(idsToDelete, ConfigurationHelper.DeleteProjectEndpoint());
    } catch (error) {
      setPageError(`An error occurred whilst deleting projects: ${error}`);
      rollback();
    }
  };

  const handleDelete = (): void => {
    setOpenModal(true);
  };

  const controlProjectActions =
    selectedProjects.length > 0 ? (
      <ProjectActions
        selectedProjects={selectedProjects}
        applyArchive={handleArchive}
        applyExport={handleExport}
        applyUnarchive={handleUnarchive}
        applyDelete={handleDelete}
      />
    ) : null;

  return (
    <div className="explore-page">
      <Grid container className="header">
        <ExploreHeader />
      </Grid>
      <div className="projects-container-wrapper">
        <Grid className="projects-container">
          <div className="projects-list">
            <div className="table-container">
              <div className="projects-table">
                <ControlHeader
                  leftControl={<Search onSearch={handleSearchParamsInput} searchModel={searchModel} />}
                  rightControl={
                    <Tabber
                      tabs={[SearchTypes.All, SearchTypes.Active, SearchTypes.Inactive]}
                      selectedOption={searchType}
                      onOptionSelected={handleSearchTypeSelected}
                    />
                  }
                />
                {controlProjectActions}
                {!pageError && (
                  <Loadable
                    loadingObject={projects}
                    loadedComponent={() => (
                      <div className={isLoading || isSearching ? "project-table-refreshing" : "project-table"}>
                        <ProjectTable
                          projectList={projects}
                          allowPaging={false}
                          selectedProjects={selectedProjects}
                          onProjectsSelected={handleProjectsSelected}
                          reloadProjectTable={performProjectQuery}
                          onScroll={handleScroll}
                          isLoading={(isSearching && searchResultPage > 0) || isLoading}
                          columnDefinition={ProjectTableDefinition()}
                        />
                      </div>
                    )}
                  />
                )}
                {pageError && <Alert severity="error">{pageError}</Alert>}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ConfirmUserActionModal
          modalState={handleCloseModal}
          action="delete"
          performAction={performDeleteProject}
          confirmationText={"Are you sure you want to delete this project? This cannot be reversed."}
        />
      </Modal>
    </div>
  );
}
