import "./range-condition-editor.scss";
import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteEditorButton } from "../../../../../../components/delete-editor-button/delete-editor-button";
import { MultipleValueEditor } from "../../../../../../components/editors/multiple";
import type { MultiRangeCondition } from "../../../../../../components/query-builder/models/multi-range-condition";
import { QueryBuilderModel } from "../../../../../../components/query-builder/models/query-builder-model";
import { useGroup, useGroupsActions, useGroupsNudge } from "../../../../store/groups";
import type { Group } from "../../../../store/groups";
import { useVariable, useVariablesActions } from "../../../../store/variables";
import type { VariableData } from "../../../../store/variables";
import { useProjectRunActions } from "../../../../store/projects";
import { useState } from "react";

type Props = {
  condition: MultiRangeCondition;
  onDelete?: () => void;
} & (
  | {
      groupId: Group["id"];
      variableId?: never;
    }
  | {
      groupId?: never;
      variableId: VariableData["id"];
    }
);

export function MultiRangeConditionEditor(props: Props): JSX.Element {
  const { condition, groupId, variableId, onDelete } = props;
  useGroupsNudge();
  const { nudgeState, removeGroupDefinition, hasGroupChanged } = useGroupsActions();
  const { haveVariablesSelectionChanged } = useVariablesActions();
  const { hasRun } = useProjectRunActions();
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  let definition: QueryBuilderModel | undefined;

  if (groupId !== undefined) {
    const group = useGroup(groupId);
    definition = group.definition;
  } else if (variableId !== undefined) {
    definition = useVariable(variableId).variableDefinition;
  }

  const handleStateSelected = (state: string[]) => {
    if (groupId !== undefined) {
      hasGroupChanged(true);
    } else if (variableId !== undefined) {
      haveVariablesSelectionChanged(true);
    }
    hasRun(false);
    condition.setValue(state);
    nudgeState();
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div
      className={`${isOnRemovalMode ? "editor-removal-mode editor-highlighted-condition" : "editor-highlighted-condition"}`}
    >
      <DeleteEditorButton
        onDelete={() => {
          if (onDelete) {
            onDelete();
            return;
          }

          if (definition === undefined) {
            console.error("Definition is undefined or null");
          } else {
            removeGroupDefinition(condition, definition);
          }
        }}
        shouldHide={true}
        onMouseEnter={() => setIsOnRemovalMode && setIsOnRemovalMode(true)}
        onMouseLeave={() => setIsOnRemovalMode && setIsOnRemovalMode(false)}
      />
      <MultipleValueEditor
        currentValue={condition.getValue()}
        options={Object.keys(condition.getAllowedValues())}
        valueName={"Age Groups"}
        onOptionChosen={handleStateSelected}
        error={error}
        showPaperComponents={true}
      />
    </div>
  );
}
