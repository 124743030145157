"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const regex = {
    nameValidation: /^[\w !"',.-]*$/,
    startsWithLetterOrNumber: /^[\dA-Za-z]/,
    endsWithLetterOrNumber: /[\dA-Za-z]$/,
    containsOnlyAlphanumeric: /^[\dA-Za-z]+$/,
    emailValidation: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
};
exports.default = regex;
