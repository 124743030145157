import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { DeleteEditorButton } from "../../../../../components/delete-editor-button/delete-editor-button";
import { DiscreetValueEditor } from "../../../../../components/editors/discreet-value";
import type { DiscreetCondition } from "../../../../../components/query-builder/models/discreet-condition";
import { useCreateAudienceContext } from "../../../context/CreateAudienceContext";
import { useState } from "react";

interface IDiscreetConditionEditorProps {
  condition: DiscreetCondition;
}

export function DiscreetConditionEditor({ condition }: IDiscreetConditionEditorProps): JSX.Element {
  const context = useCreateAudienceContext();
  const [isOnRemovalMode, setIsOnRemovalMode] = useState(false);

  const handleStateSelected = (state: string): void => {
    condition.setValue(state);
    context.onChange(condition);
  };

  const validationState = condition.getValidationState();

  const error = validationState.isValid ? null : (
    <WppInlineMessage message={validationState.invalidReason} size="s" type="error" />
  );

  return (
    <div className={`${isOnRemovalMode ? "editor-removal-mode editor-state-condition" : "editor-state-condition"}`}>
      <DeleteEditorButton
        onDelete={() => {
          context.onDelete(condition);
        }}
        shouldHide={true}
        onMouseEnter={() => setIsOnRemovalMode(true)}
        onMouseLeave={() => setIsOnRemovalMode(false)}
      />
      <DiscreetValueEditor
        currentValue={condition.getValue()}
        options={condition.getAllowedValues()}
        valueName={condition.getDimensionName()}
        onOptionChosen={handleStateSelected}
        error={error}
      />
    </div>
  );
}
